import React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <div className="container">
    <h1><br/>Not found</h1>
    <h1>You just hit a route that doesn&#39;t exist... <span className="is-italic">the sadness</span>. Return to <Link to="/">Index</Link></h1>
  </div>
)

export default NotFoundPage
